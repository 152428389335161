<template>
  <div class="content-profile-user">

    <div v-if="Object.keys(oUserProfileDetail).length !== 0" class="global-content-detail">
      <div ref="searchbarGlobal">
        <header-component-global sTextTitle="Perfil de usuario" sTextBtnAdd="" :bAdminPermissions="false"
          setDialog="" />
      </div>
      <div class="content-information">
        <div v-if="!bModify" class="global-content-img">
          <div class="global-content-backgorund">
            <img class="global-img-src" :src="sImgEdit" />
          </div>
        </div>
        <div v-else class="global-content-img">
          <div v-if="dataImg !== null || this.sImgEdit !== null" class="global-content-backgorund">
            <v-btn @click="editImg" icon class="global-button-edit-item">
              <v-icon size="20px"> mdi-pencil </v-icon>
            </v-btn>
            <img v-if="this.sImgEdit !== null" class="global-img-src" :src="this.sImgEdit" />
            <img v-else class="global-img-src" :src="maskedFile(dataImg)" />
          </div>
          <div v-else class="global-content-backgorund">
            <v-btn @click="$refs.inputUpload.click()" class="global-button-add-img">
              <div>
                <v-icon size="40px"> mdi-image-plus </v-icon>
                <p class="global-text-img-add">Arrastra ó da clic</p>
                <p class="global-text-img-add">para añadir</p>
              </div>
            </v-btn>
          </div>
          <input ref="inputUpload" style="display: none" type="file" size="60" accept=".jpg, .jpeg, .png"
            multiple="false" @change="uploadImg" />
        </div>
        <v-container fluid>
          <v-row>
            <v-container v-if="!bModify">
              <v-row class="content-text">
                <v-col cols="12">
                  <p class="p-title">
                    {{
                        oUserProfileDetail.sName +
                        " " +
                        oUserProfileDetail.sLastname
                    }}
                  </p>
                  <p class="p-occupation">{{ oUserProfileDetail.sRole }}</p>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="5" xl="4">
                  <div class="content-icon-text">
                    <v-icon class="icon-administrator">mdi-email-outline</v-icon>
                    <p class="p-text">{{ oUserProfileDetail.sEmail }}</p>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="3" xl="4">
                  <div class="content-icon-text">
                    <v-icon class="icon-administrator">mdi-phone-outline</v-icon>
                    <p v-if="formatPhone(oContactInfo)" class="p-text">
                      <!-- {{ oContactInfo.sPhoneNumber }} -->
                      {{ formatPhone(oContactInfo) }}
                    </p>
                    <p v-else class="text-data-empty mb-0">
                      <!-- {{ oContactInfo.sPhoneNumber }} -->
                      Sin información
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="3" xl="4">
                  <div class="content-icon-text">
                    <v-icon class="icon-administrator">mdi-calendar-blank-outline</v-icon>
                    <p class="p-text">
                      {{ oUserProfileDetail.tCreatedAt }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else fluid>
              <v-row class="">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field v-model="sNameEdit" label="Nombre" placeholder="Nombre..." class="global-text-field"
                    color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                    persistent-placeholder outlined maxlength="50" @keyup="validateForm()">
                    <template slot="label">
                      <span>Nombre<span class="important-data">*</span></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field v-model="sLastnameEdit" label="Apellido" placeholder="Apellido..."
                    class="global-text-field" color="var(--primary-color-border-input)"
                    background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="50"
                    @keyup="validateForm()">
                    <template slot="label">
                      <span>Apellido<span class="important-data">*</span></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field v-model="sRoleEdit" label="Ocupación laboral"
                    placeholder="Ocupación laboral..." class="global-text-field"
                    color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                    persistent-placeholder outlined maxlength="40" @keyup="validateForm()">
                    <template slot="label">
                      <span>Ocupación laboral<span class="important-data">*</span></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field v-model="sEmailEdit" disabled readonly label="Correo electrónico"
                    placeholder="Correo electrónico..." class="global-text-field"
                    color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                    persistent-placeholder outlined maxlength="70" @keyup="validateForm()">
                    <template slot="label">
                      <span>Correo electrónico</span>
                      <v-tooltip top content-class="global-tooltip-black">
                        <template v-slot:activator="{ on, attrs }">
                          <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> -->
                          <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>

                          <!-- </v-btn> -->
                        </template>
                        <span>Campo no modificable.</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                  <phone-component-global :bImportantDate="true" @updateCountryCallingCode="updateCountryCallingCode"
                    :sCountryCallingCodeOrigin="sCountryCallingCodeEdit" @updateAreaCallingCode="updateAreaCallingCode"
                    :sAreaCallingCodeOrigin="sAreaCallingCodeEdit" @updatePhoneNumber="updatePhoneNumber"
                    :sPhoneNumberOrigin="sPhoneNumberEdit" @updatePhoneExtension="updatePhoneExtension"
                    :sPhoneExtensionOrigin="sPhoneExtensionEdit" />
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </div>
      <v-divider class="divider-global mt-9"></v-divider>

      <v-divider class="divider-sidebar-global"></v-divider>
      <div class="content-btns-accions">
        <v-spacer></v-spacer>

        <div class="content-btn-change-password">
          <v-btn v-if="!bModify" class="global-btn-neutral" @click="setDialogChangePassword()">
            Cambiar contraseña
          </v-btn>
        </div>
        <div v-if="bModify" v-show="!bEdit" class="content-btn-second">
          <v-btn class="global-btn-neutral btn-discard-change" @click="discardChange()">
            Descartar cambios
          </v-btn>
        </div>

        <div class="content-btn-primary">
          <v-btn v-if="!bModify" class="global-btn-primary" @click="bModify = !bModify">
            Modificar
          </v-btn>
          <v-btn v-else v-show="!bEdit" @click="changeUser()" :loading="bLoading"
            class="global-btn-primary btn-save-change">
            Guardar cambios
          </v-btn>
          <v-btn v-if="bModify" v-show="bEdit" class="global-btn-neutral" @click="bModify = !bModify">
            Cancelar
          </v-btn>
        </div>
      </div>
    </div>
    <skeleton-user-profile-component v-else />
    <dialog-change-password-component @setDialogChangePassword="setDialogChangePassword"
      :bDialogChangePassword="bDialogChangePassword" />

    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  name: "UserProfile",
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,

      bLoading: false,
      dataImg: null,
      bModify: false,
      bEdit: true,
      sImgEdit: [],
      sImgOrigin: [],
      sNameEdit: "",
      sLastnameEdit: "",
      sEmailEdit: "",
      sRoleEdit: "",
      tCreatedAtEdit: "",
      sCountryCallingCodeEdit: "51",
      sAreaCallingCodeEdit: "1",
      sPhoneNumberEdit: "",
      sPhoneExtensionEdit: "1379",
      aPermission: [],
      aPermissionEdit: [],
      aPermissionById: [],
      aPermissionByIdEdit: [],
      sDateEdit: "",
      oUserProfileDetail: {},
      oContactInfo: {},
      aMonth: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      bDialogChangePassword: false,
    };
  },
  beforeMount() {
    this.getUserDetail();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize ", this.matchHeight);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.searchbarGlobal !== undefined) {
          this.heightMax = this.$refs.searchbarGlobal.clientHeight;
          this.heightMax = this.heightMax + 133; //123 IS NAVBAR
        }
        if (window.innerWidth > 600) {
          this.screenHeight = window.innerHeight - 100;
        } else {
          this.screenHeight = window.innerHeight - 100;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    getUserDetail() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.get(
        `${URI}/api/sp/v1/users/${this.$store.state.sUserId}/profile`,
        config,
        payload
      )
        .then((response) => {
          this.bModify = false;

          this.oUserProfileDetail = response.data.results;
          this.oContactInfo = response.data.results.oContactInfo;

          this.sNameEdit = this.oUserProfileDetail.sName;

          this.sLastnameEdit = this.oUserProfileDetail.sLastname;

          this.sEmailEdit = this.oUserProfileDetail.sEmail;

          this.sRoleEdit = this.oUserProfileDetail.sRole;

          this.tCreatedAtEdit = this.oUserProfileDetail.tCreatedAt;

          this.sCountryCallingCodeEdit = this.oContactInfo.sCountryCallingCode;

          this.sAreaCallingCodeEdit = this.oContactInfo.sAreaCallingCode;

          this.sPhoneNumberEdit = this.oContactInfo.sPhoneNumber;

          this.sPhoneExtensionEdit = this.oContactInfo.sPhoneExtension;

          this.sImgEdit = this.oUserProfileDetail.sUrl.length > 1
            ? this.oUserProfileDetail.sUrl[2].sUrl
            : require("@/assets/Empty_Profile.png");
          this.sImgOrigin = this.oUserProfileDetail.sUrl.length > 1
            ? this.oUserProfileDetail.sUrl[2].sUrl
            : require("@/assets/Empty_Profile.png");
          this.bEdit = true;
          this.$store.commit("setRefreshImg", {
            sUrlImg: this.sImgOrigin,
          });
          this.$store
            .dispatch("getPermissionsByUserGlobal")
            .then((resp) => { })
            .catch((err) => {
              this.Error(err);
            });
          this.$store.commit("refresher", false);
        })
        .catch((error) => {

          this.Error(error.response.data);
        });
    },
    discardChange() {
      this.sNameEdit = this.oUserProfileDetail.sName;
      this.sLastnameEdit = this.oUserProfileDetail.sLastname;
      this.sEmailEdit = this.oUserProfileDetail.sEmail;
      this.sRoleEdit = this.oUserProfileDetail.sRole;
      this.tCreatedAt = this.oUserProfileDetail.tCreatedAt;

      this.sCountryCallingCodeEdit = this.oContactInfo.sCountryCallingCode;
      this.sAreaCallingCodeEdit = this.oContactInfo.sAreaCallingCode;
      this.sPhoneNumberEdit = this.oContactInfo.sPhoneNumber;
      this.sPhoneExtensionEdit = this.oContactInfo.sPhoneExtension;

      this.sImgEdit = this.sImgOrigin;

      this.bModify = !this.bModify;
      this.bEdit = true;
    },
    validateForm() {
      this.bEdit =
        this.sImgEdit === this.sImgOrigin &&
        (this.sNameEdit.trim() === "" ||
          this.sNameEdit.trim() == this.oUserProfileDetail.sName.trim()) &&
        (this.sLastnameEdit.trim() === "" ||
          this.sLastnameEdit.trim() ===
          this.oUserProfileDetail.sLastname.trim()) &&
        (this.sEmailEdit.trim() === "" ||
          this.sEmailEdit.trim() === this.oUserProfileDetail.sEmail.trim()) &&
        (this.sRoleEdit.trim() === "" ||
          this.sRoleEdit.trim() === this.oUserProfileDetail.sRole.trim()) &&
        (this.sCountryCallingCodeEdit.trim() === "" ||
          this.sCountryCallingCodeEdit.trim() ===
          this.oContactInfo.sCountryCallingCode.trim()) &&
        (this.sAreaCallingCodeEdit.trim() === "" ||
          this.sAreaCallingCodeEdit.trim() ===
          this.oContactInfo.sAreaCallingCode.trim()) &&
        (this.sPhoneNumberEdit.trim() === "" ||
          this.sPhoneNumberEdit.trim() ===
          this.oContactInfo.sPhoneNumber.trim()) &&
        this.sPhoneExtensionEdit.trim() ===
        this.oContactInfo.sPhoneExtension.trim();
    },
    fieldNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    changeUser() {
      this.bLoading = true;

      const payload = {
        sName: this.sNameEdit,
        sLastname: this.sLastnameEdit,
        // sEmail: this.sEmailEdit,
        sRole: this.sRoleEdit,
        sCountryCallingCode: this.sCountryCallingCodeEdit,
        sAreaCallingCode: this.sAreaCallingCodeEdit,
        sPhoneNumber: this.sPhoneNumberEdit,
        sPhoneExtension: this.sPhoneExtensionEdit,
        // aPermission: this.aPermissionEdit
        //   .filter((e) => e.sAdmin === true || e.sDetail === true)
        //   .map((e) => {
        //     let arry = [];
        //     if (e.sAdmin == true) {
        //       arry = e.sPermissionId;
        //     }
        //     return arry;
        //   }),
      },
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.put(
        `${URI}/api/sp/v1/users/${this.$store.state.sUserId}/profile`,
        payload,
        config
      )
        .then((response) => {
          if (this.dataImg !== null) {
            this.addImgAdmin(
              this.$route.params.id,
              response.data.message,
              response.data.results.sName +
              " " +
              response.data.results.sLastname
            );
          } else {
            this.bLoading = false;

            this.$store.commit("refresher", true);
            this.$store.commit("setRefreshFullName", {
              sFullname:
                response.data.results.sName +
                " " +
                response.data.results.sLastname,
            });

            this.Success(
              response.data.message,
              response.data.results.sName +
              " " +
              response.data.results.sLastname
            );
          }
        })
        .catch((error) => {

          this.bLoading = false;
          this.Error(error.response.data);
        });
    },
    addImgAdmin(id, sMessage, sFullName) {
      let form = new FormData();
      form.append("aPicture", this.dataImg[0]);

      const config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };

      DB.patch(
        `${URI}/api/sp/v1/users/${this.$store.state.sUserId}/profile`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.$store.commit("refresher", true);
          this.$store.commit("setRefreshFullName", { sFullname: sFullName });

          this.Success(sMessage, sFullName);
        })
        .catch((error) => {

          this.Error(error.response.data);
        });
    },
    uploadImg: function (e) {
      this.sImgEdit = null;
      this.dataImg = null;
      const files = e.target.files;
      this.dataImg = files;
      this.validateForm();
    },
    editImg: function () {
      this.$refs.inputUpload.click();
    },
    maskedFile: function (file) {
      const objectURL = URL.createObjectURL(file[0]);
      return objectURL;
    },

    setDialogChangePassword() {
      this.bDialogChangePassword = !this.bDialogChangePassword
    },
    //UPDATE DATE PHONE
    updateCountryCallingCode(val) {
      this.sCountryCallingCodeEdit = val;
      this.validateForm();
    },
    updateAreaCallingCode(val) {
      this.sAreaCallingCodeEdit = val;
      this.validateForm();
    },
    updatePhoneNumber(val) {
      this.sPhoneNumberEdit = val;
      this.validateForm();
    },
    updatePhoneExtension(val) {
      this.sPhoneExtensionEdit = val;
      this.validateForm();
    },
    formatPhone(oContactInfo) {
      let sCountryCallingCode = oContactInfo.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 4),
              "-",
              oContactInfo.sPhoneNumber.slice(4),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        case "1":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 3),
              "-",
              oContactInfo.sPhoneNumber.slice(3),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        default:
          return (
            null
          )

      }
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refreshTable: function () {
      this.getUserDetail();
    },
  },
};
</script>

<style scoped>
.content-profile-user {
  padding: 0px 30px 0px 35px;
}

.content-information {
  display: flex;
  margin-bottom: 15px;
}

.contenr-table-permission {
  padding: 0px !important;
}

.text-title-permissions {
  text-align: start;
  letter-spacing: 4.8px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  /* margin-right: 10px; */
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}

.p-title {
  color: var(--primary-color-text);
  text-transform: uppercase;
  font-family: "pop-Semibold";
  font-size: 30px;
  margin-bottom: 0px;
}

.p-occupation {
  color: var(--primary-color-text);
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-bottom: 0px;
}

.content-icon-text {
  display: flex;
}

.icon-administrator {
  color: var(--primary-color-text) !important;
  margin-right: 15px;
}

.p-text {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.content-all {
  align-self: center !important;
}

.content-text-field {
  margin-top: 1px;
  margin-left: 10px;
}

.content-switch {
  display: flex;
  justify-content: center;
  width: 100%;
}

.switch-permission {
  margin: 0px;
  padding: 0px;
}

.content-btns-accions {
  display: flex;
  margin-top: 25px;
  width: 100% !important;
}

.content-btn-primary {
  width: 200px;
  margin-left: 15px;
}

.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

.btn-discard-change {
  margin-left: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-information {
    display: block;
  }

  .p-title {
    color: var(--primary-color-text);
    text-transform: uppercase;
    font-family: "pop-Semibold";
    font-size: 30px;
    text-align: center;
    margin-bottom: 0px;
  }

  .p-occupation {
    color: var(--primary-color-text);
    font-family: "pop-Semibold";
    font-size: 14px;
    text-align: center;
    margin-bottom: 0px;
  }

  .content-icon-text {
    justify-content: center;
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-change-password {
    margin-bottom: 15px;
  }

  .btn-discard-change {
    margin-left: 0px;
  }

  .content-text-field {
    margin-top: 1px;
    margin-left: 0px;
  }

  .content-btn-primary {
    width: 100%;
    margin-left: 0px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-text-field {
    margin-top: 1px;
    margin-left: 10px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
